import React, { useEffect, useState } from "react"
import isEqual from "lodash/isEqual"
import random from "lodash/random"
import { useSelector } from "react-redux"

import AnnotatedPassage from "../passage"
import BookQuestion from "./question"
import CONFIG from "../../config"
import ConceptCard from "../library/concept"
import ImageZoom from "./imageZoom"
import { Choice, Prompt } from "../../hasura/slices/sequence"
import { Concepts_concepts } from "../../hasura/queries/types/Concepts"
import { Passage } from "../../hasura/slices/passage"
import { QuestionsForPassages_questions } from "../../hasura/queries/types/QuestionsForPassages"
import { bookSelector, BookState } from "../../hasura/slices/book"
import { caseInsEq, hasWindow, imageFor, searchQueryParams } from "../../lib/helpers"
import { imageForPassage, isConceptQuestion, QuestionLog } from "./helpers"
import { sleep } from "../../lib/helpers"
import { usePrevious } from "../../hooks/usePrevious"
import { UserState, userSelector } from "../../hasura/slices/user"

const mediumZoom = hasWindow ? require("medium-zoom") : undefined

interface Props {
  handleGuess: (c: Choice, question: QuestionsForPassages_questions, isHint: boolean) => void
  mainOpacity: number
  hintsRemaining: number
  mustAnswerQuestion: boolean
  passages: Passage[]
  questionIds: number[]
  questionLog: QuestionLog[]
  handleClickedHint: () => void
}

export default function BookPage(props: Props) {
  const { questionsForBookFiltered }: BookState = useSelector(bookSelector)
  const { isAdmin }: UserState = useSelector(userSelector)

  const zoom = React.useRef(hasWindow ? mediumZoom.default({ background: "rgba(255,255,255,0.75)", margin: 48 }) : undefined)

  const debug = searchQueryParams("debug") !== undefined

  const [pageOneOpacity, setPageOneOpacity] = React.useState(0)
  const [pageOneFlipImage, setPageOneFlipImage] = React.useState(false)
  const [pageTwoFlipImage, setPageTwoFlipImage] = React.useState(false)
  const [pageTwoOpacity, setPageTwoOpacity] = React.useState(0)
  const [displayConcept, setDisplayConcept] = useState<Concepts_concepts | undefined>()
  const [unobscurePassageIds, setUnobscurePassageIds] = useState<number[]>([])

  const previousPassages = usePrevious(props.passages)

  useEffect(() => {
    ;(async () => {
      if (!props.passages?.length || isEqual(previousPassages, props.passages)) return

      setPageOneFlipImage(random(0, 100) > 50)
      setPageTwoFlipImage(random(0, 100) > 50)
      setPageOneOpacity(0)
      setPageTwoOpacity(0)
      await sleep(CONFIG.IS_DEVELOPMENT || isAdmin ? 0 : 0.7)
      setPageOneOpacity(1)
      await sleep(CONFIG.IS_DEVELOPMENT || isAdmin ? 0 : 1.1)
      setPageTwoOpacity(1)
    })()
  }, [props.passages])

  return (
    <div style={{ gap: "100px" }} className="d-flex border-left-border-right px-3">
      <div
        onClick={() => setDisplayConcept(undefined)}
        style={{
          backgroundColor: "rgba(255,255,255,0.75)",
          pointerEvents: displayConcept ? "auto" : "none",
          opacity: displayConcept ? 1 : 0,
        }}
        className="position-fixed l-0 r-0 vw-100 vh-100 flex-center zztop transition-s"
      >
        {displayConcept && (
          <ConceptCard displayImages obscurity={displayConcept.obscurity} isAdmin={false} imagesForUser={[]} isBook concept={displayConcept} />
        )}
      </div>

      {props.passages.map((passage, idx) => {
        const image = imageForPassage(passage)
        const question = (questionsForBookFiltered || []).find((q) => q.passage_id === passage.id && props.questionIds.includes(q.id))
        const hide = question ? (question!.content.prompt as Prompt[]).find((p) => p.hide)?.value : undefined
        const highlight = question && isConceptQuestion(question) ? question.concept?.display_name : undefined
        const isSolved = props.questionLog.filter((q) => q.questionId === question?.id && q.correct).length > 0
        const pageOne = idx === 0

        const opacity = props.mainOpacity === 0 ? 0 : pageOne ? pageOneOpacity : pageTwoOpacity

        const imageComponent = image ? (
          <div className="mb-3 d-flex flex-column align-items-center">
            <ImageZoom src={imageFor(image?.image.s3_path)} zoom={zoom.current} />

            {image.image.status === "done" && image.image.caption && (
              <p className="garamond text-m font-italic text--gray8 text-center mt-2">{image.image.caption}</p>
            )}

            {debug && (
              <p className="text-xs text-link" onClick={() => window.open(`${CONFIG.DOMAIN}/image?id=${image.image.id}`, "_blank")}>
                ID {image.image.id}
              </p>
            )}
          </div>
        ) : null

        const flipImage = pageOne ? pageOneFlipImage : pageTwoFlipImage
        const isCustomQuestion = caseInsEq(question?.type || "", "custom")

        return (
          <div className="transition-xs" style={{ padding: "100px 20px", opacity, flex: 1, minWidth: 0 }} key={idx}>
            {flipImage && imageComponent}

            <AnnotatedPassage
              obscure={isCustomQuestion && !unobscurePassageIds.includes(passage.id)}
              setDisplayConcept={setDisplayConcept}
              highlight={highlight}
              hide={isSolved ? undefined : hide}
              annotated={passage.annotated}
              concepts={[]}
            />

            {question && (
              <BookQuestion
                hintsRemaining={props.hintsRemaining}
                mustAnswerQuestion={props.mustAnswerQuestion}
                questionLog={props.questionLog}
                handleGuess={(c: Choice, question: QuestionsForPassages_questions, isHint: boolean) => {
                  if (isHint && question.type === "Custom") {
                    setUnobscurePassageIds([...unobscurePassageIds, passage.id])
                    props.handleClickedHint()
                  } else {
                    props.handleGuess(c, question, isHint)
                  }
                }}
                question={question}
              />
            )}

            {debug && (
              <p className="text-xs text-link" onClick={() => window.open(`${CONFIG.DOMAIN}/tagging?id=${passage.id}`, "_blank")}>
                ID {passage.id}
              </p>
            )}

            {!flipImage && imageComponent}
          </div>
        )
      })}

      {props.passages.length === 1 && <div style={{ flex: 1 }} />}
    </div>
  )
}
