import React, { useEffect } from "react"
import first from "lodash/first"
import { useSelector } from "react-redux"

import CardSettings from "./cardSettings"
import MainConceptData from "./mainConceptData"
import { Concepts_concepts, Concepts_concepts_root_appearances } from "../../hasura/queries/types/Concepts"
import { User_users_by_pk_experience_concept, User_users_by_pk_experience_concept_root_appearances } from "../../hasura/queries/types/User"
import { imageFor } from "../../lib/helpers"
import { userSelector, UserState } from "../../hasura/slices/user"

export type RootAppearance = User_users_by_pk_experience_concept_root_appearances | Concepts_concepts_root_appearances

// @ts-ignore
import diamondIcon from "../../lib/images/diamond.svg"

interface Props {
  concept: User_users_by_pk_experience_concept | Concepts_concepts
  correct?: number
  imagesForUser: number[]
  displayImages?: boolean
  isAdmin: boolean
  setDisplayConceptId?: (displayConceptId?: number) => void
  obscurity: number | null
  seen?: number
  isBook?: boolean
}

const instanceOfConcept = (object: any): object is Concepts_concepts => "is_basic" in object

export default function ConceptCard(props: Props) {
  const { concept, isBook } = props

  useEffect(() => {
    scrollTo(0, 0)
  }, [concept])

  const { isAdmin, isStudent, hasPremium }: UserState = useSelector(userSelector)

  const image = first(concept.concept_image_appearances.filter((i) => !isStudent || props.displayImages || props.imagesForUser.includes(i.image.id)))

  const isLocked = !isBook && !isAdmin && instanceOfConcept(props.concept) && !props.concept.is_basic && !hasPremium

  const hasImage = image !== undefined

  return (
    <div
      onClick={() => {
        if (!props.setDisplayConceptId || isAdmin) return

        // @ts-ignore
        props.setDisplayConceptId(concept.id)
      }}
      className={`library-card border border-primary rounded ${isAdmin || isStudent || isBook ? "" : ""}`}
      style={
        hasImage
          ? {
              backgroundImage: `url(${imageFor(image.image.s3_path)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }
          : {}
      }
    >
      <div
        style={{ minHeight: "100%" }}
        className={`p-2 position-relative w-100 d-flex justify-content-${hasImage ? "end" : "start"} justify-space-between flex-column overflow-auto`}
      >
        {isLocked && <img className="icon-xs m-2 position-absolute t-0 r-0" src={diamondIcon} />}

        <MainConceptData hasImage={hasImage} concept={concept} />
      </div>

      <div className="w-100">
        {isAdmin && (
          <CardSettings
            // @ts-ignore
            entities={concept.questions!.map((q) => q.id)}
            entity="concept"
            // @ts-ignore
            id={concept.id}
            value={concept.display_name}
            isEditable
            updatedAt={concept.updated_at}
          />
        )}
      </div>
    </div>
  )
}
