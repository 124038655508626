import orderBy from "lodash/orderBy"

import { QuestionsForBook_concept_questions } from "../../hasura/queries/types/QuestionsForBook"
import { Passage } from "../../hasura/slices/passage"

export interface QuestionLog {
  correct: boolean
  passageId: number
  questionId: number
  value: string
  isHint: boolean
}

export const BOOK_QUESTION_COUNT = 10

export const isCorrect = (id: number, questionLog: QuestionLog[]) => questionLog.filter((q) => q.passageId === id).every((q) => q.correct)

export const isCorrectWithHint = (id: number, questionLog: QuestionLog[]) => Boolean(questionLog.find((q) => q.passageId === id)?.isHint)

export const imageForPassage = (passage: Passage) => orderBy(passage.illustrated_passages, (i) => i.image.quality || 1, "desc")[0]

export const isConceptQuestion = (object: any): object is QuestionsForBook_concept_questions => "concept" in object
