import React from "react"

// @ts-ignore
export default function ImageZoom({ zoom, src }) {
  const zoomRef = React.useRef(zoom)

  const attachZoom = (image: any) => zoomRef.current.attach(image)

  return <img className="mw-100 max-height-450px w-auto h-auto rounded" src={src} ref={attachZoom} />
}
