import React, { useEffect } from "react"
import { Button } from "reactstrap"

import BookDisplay from "./display"
import BookList from "./list"
import { Books_books } from "../../hasura/queries/types/Books"
import { shootConfetti } from "../../lib/helpers"

// @ts-ignore
import starIcon from "../../lib/images/star.svg"
// @ts-ignore
import masteredIcon from "../../lib/images/trophy.svg"

interface Props {
  book: Books_books
  questionCount: number
  correctCount: number
  hintsUsed: number
}

export default function BackCover(props: Props) {
  const mastered = props.correctCount === props.questionCount && !props.hintsUsed

  useEffect(() => {
    shootConfetti(mastered ? 10 : 3, mastered ? ["#00d181"] : undefined)
  }, [mastered])

  return (
    <div className="d-flex align-items-center vh-100">
      <div className="vh-100 d-flex align-items-center justify-content-center flex-column flex-even">
        <div className="flex-center mb-3">
          <h1 className="text-center text-xl bold m-0">{mastered ? "Perfect score! Book mastered!" : "Book completed!"}</h1>
          {mastered && <img className="ml-2 icon-m" src={masteredIcon} />}
        </div>

        <BookDisplay large isUnlocked {...props} />

        <div className="flex-center mt-2">
          <p className="text-l bold mb-0">
            {props.correctCount} / {props.questionCount}
          </p>

          <img className="icon-s ml-1 mb-1" src={starIcon} />
        </div>

        <div className="my-4 text-m text-center">
          {mastered ? (
            <p className="mb-2">Play again to win more stars and trophies.</p>
          ) : (
            <p>
              Play again to win more stars.
              <br />
              Get a perfect score to win a trophy.
            </p>
          )}

          <Button onClick={() => window.location.reload()} size="lg" color="primary" className="min-width-150px">
            Play again
          </Button>
        </div>
      </div>

      <div className="vh-100 d-flex flex-column flex-even bg--gray2">
        <h2 className="text-xl mb-4 bold px-5 pt-5">Play next</h2>

        <div className="px-5 pb-5 overflow-scroll">
          <BookList isPlayNext={true} justPlayed={props.book.title} />
        </div>
      </div>
    </div>
  )
}
