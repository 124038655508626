import React from "react"
import sortBy from "lodash/sortBy"
import { Badge } from "reactstrap"

import { Concepts_concepts, Concepts_concepts_root_appearances } from "../../hasura/queries/types/Concepts"
import { User_users_by_pk_experience_concept, User_users_by_pk_experience_concept_root_appearances } from "../../hasura/queries/types/User"
import { getDefinitionHighlightIndexes } from "../../lib/helpers"

export type RootAppearance = User_users_by_pk_experience_concept_root_appearances | Concepts_concepts_root_appearances

interface Props {
  concept: User_users_by_pk_experience_concept | Concepts_concepts
  hasImage?: boolean
}

export default function MainConceptData(props: Props) {
  const { concept, hasImage } = props
  const { display_name, definition } = concept!

  return (
    <div className="p-2 rounded text-m" style={hasImage ? { color: "white", backgroundColor: "rgba(0,0,0,0.6)" } : {}}>
      <div className="d-flex align-items-start justify-content-between">
        <p className={`m-0 bold`}>{display_name}</p>
      </div>

      {(concept?.root_appearances.length || 0) > 0 && (
        <div className="d-flex gap-5px">
          {
            // @ts-ignore
            sortBy(concept.root_appearances || [], "start_index").map((r: RootAppearance, idx) => (
              <Badge className="bg--white text--gray8 text-xxs border border-dark" key={idx}>
                {r.value.toUpperCase()}
              </Badge>
            ))
          }
        </div>
      )}

      <p className="mb-0">
        {definition?.split("").map((char, idx) => (
          <span
            style={{ borderWidth: "4 !important" }}
            key={idx}
            className={`${getDefinitionHighlightIndexes(concept).includes(idx) ? "bottom-border-secondary" : ""}`}
          >
            {char}
          </span>
        ))}
      </p>
    </div>
  )
}
