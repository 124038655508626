import React from "react"
import shuffle from "lodash/shuffle"
import { useSelector } from "react-redux"

import { Annotated, Token } from "../../hasura/slices/passage"
import { Concepts_concepts } from "../../hasura/queries/types/Concepts"
import { conceptSelector, ConceptState } from "../../hasura/slices/concept"
import { includeRightMargin } from "../../lib/helpers"

interface Props {
  annotated: Annotated
  concepts: any[]
  hide?: string
  highlight?: string
  obscure: boolean
  setDisplayConcept: (concept: Concepts_concepts | undefined) => void
}

const MAX_DEFINITION_LENGTH = 100

interface GamifiedToken {
  token: Token
  hide: boolean
  highlight: boolean
  concept: Concepts_concepts | undefined
}

const isolateToken = (token: GamifiedToken) => Boolean(token.hide || token.highlight)

const isQuotation = (token?: Token) => ['"', "'"].includes(token?.lemma || "")

const getQuotationIndex = (tokens: Token[], token: Token) =>
  tokens
    .filter(isQuotation)
    .map((t) => t.index)
    .indexOf(token.index)

const joinedTokens = (gamifiedTokens: GamifiedToken[]) => {
  let gamified: GamifiedToken[] = []
  let lastIsTextWrapChar = false
  let lastIsolated = true
  const tokens = gamifiedTokens.map((t) => t.token)

  gamifiedTokens.forEach((token, idx) => {
    const value = token.token.value
    const isTextWrapChar = [",", ".", ")", "'s"].includes(value) || (isQuotation(token.token) && getQuotationIndex(tokens, token.token) === 1)
    const combineWithLast = !isolateToken(token) && ((!lastIsolated && isTextWrapChar) || lastIsTextWrapChar)

    if (combineWithLast) {
      const addSpace = includeRightMargin(tokens, token.token, idx)
      const combinedValue = gamified.slice(-1)[0].token.value + value + (addSpace ? " " : "")
      const combinedToken = { ...gamified.slice(-1)[0].token, value: combinedValue }
      gamified = gamified.slice(0, -1).concat({ ...gamified.slice(-1)[0], token: combinedToken })
    } else {
      gamified.push(token)
    }

    lastIsTextWrapChar = ["("].includes(token.token.value) || (isQuotation(token.token) && getQuotationIndex(tokens, token.token) === 0)
    lastIsolated = isolateToken(token)
  })

  return gamified
}

export default function AnnotatedPassage(props: Props) {
  const { annotated } = props

  const { concepts }: ConceptState = useSelector(conceptSelector)

  const tokens = annotated.tokens.map((token, idx) => {
    const hide = props.hide === token.value
    const highlight = props.highlight === token.lemma

    const concept: Concepts_concepts | undefined =
      !hide && !annotated.tokens.slice(0, idx).some((t) => t.lemma === token.lemma)
        ? concepts?.find((c) => c.definition && c.definition.length < MAX_DEFINITION_LENGTH && c.display_name === token.lemma)
        : undefined

    return { token, hide, highlight, concept }
  })

  const joined = joinedTokens(tokens)

  return (
    <div
      style={{ whiteSpace: "pre-line", textShadow: !props.obscure ? "" : "0 0 10px rgba(0,0,0,0.5)", color: !props.obscure ? "" : "transparent" }}
      className="text-xl mb-3 garamond transition-s"
    >
      {annotated.pretext && <span className="noselect mr-1">{annotated.pretext}</span>}

      {joined.map((data, idx) => {
        const { token, hide, highlight, concept } = data

        const addSpace = includeRightMargin(
          joined.map((t) => t.token),
          token,
          idx
        )

        return (
          <span
            key={idx}
            className={`
              display-inline position-relative
              ${concept && !hide && !highlight ? "bottom-border-secondary" : ""}
              ${addSpace ? "mr-1" : ""}
              ${hide ? "bg--gray4 px-2 rounded text--transparent noselect" : ""}
              ${highlight ? "bg--primary5 px-2 rounded noselect" : ""}
              ${concept && !highlight ? "pointer z-index-900" : "pe-none noselect"}
          `}
          >
            <span
              onClick={() => {
                if (concept && !highlight) props.setDisplayConcept(concept)
              }}
            >
              {hide ? shuffle(token.value.split("")) : token.value}
            </span>
          </span>
        )
      })}

      {annotated.posttext && <span className="noselect ml-1">{annotated.posttext}</span>}
    </div>
  )
}
