import React from "react"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"

import { pluralize } from "../../lib/helpers"
import { UserState, userSelector } from "../../hasura/slices/user"
import { CurriculumState, curriculumSelector } from "../../hasura/slices/curriculum"
import { deleteConceptAction, fetchConceptsAction } from "../../hasura/slices/concept"

interface Props {
  entity: string
  id: number
  value?: string
  entities: (string | number)[]
  updatedAt: any
  isEditable: boolean
}

export default function CardSettings(props: Props) {
  const { isEditable, entities, entity, value, updatedAt } = props

  const dispatch = useDispatch()
  const { accessToken }: UserState = useSelector(userSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const handleDeleteConcept = async () => {
    if (!accessToken) return

    await dispatch(deleteConceptAction(accessToken, props.id))
    dispatch(fetchConceptsAction(accessToken, curriculum?.id))
  }

  return (
    <p className="d-flex justify-content-center text-xs gray6 m-0 single-line">
      <span
        className={`${entities.length ? "underline pointer" : "text--danger pe-none"}`}
        onClick={() => window.open(`${window.location.origin}/play?type=test&ids=${entities.join()}`, "_blank")}
      >
        {pluralize("Q", entities.length)}
      </span>

      <span className="mx-2">·</span>

      {isEditable && (
        <span className="underline pointer" onClick={() => navigate(`/${entity === "passage" ? "tagging?id=" : "concept?values="}${value}`)}>
          Edit
        </span>
      )}

      {isEditable && <span className="mx-2">·</span>}

      {isEditable && (
        <span className="underline pointer" onClick={handleDeleteConcept}>
          Delete
        </span>
      )}

      {isEditable && <span className="mx-2">·</span>}

      <span className="">Updated {moment(updatedAt).fromNow()}</span>
    </p>
  )
}
